import React from 'react';

class ShareAmp extends React.Component {

    render() {
        return <amp-addthis width="320" height="65"  data-pub-id="ra-634591418b677005" data-widget-id="t13p" data-widget-type="inline"></amp-addthis>;
    }
}

export default ShareAmp;
