import React from 'react'
import Layout from '../components/layout/index.amp'
import {Basic, Facebook, Twitter} from '../components/SEO'
import Share from '../components/share/sharing.amp'
import {metadata} from '../../config'
import loadable from '@loadable/component'
import {orderBy as _orderBy} from 'lodash'
import {isMobile} from 'react-device-detect'
import Helmet from 'react-helmet'
import CategoryTitleBordered from '../components/helpers/categoryTitleBordered'
import EmbedYoutubeAmp from '../components/youtube-video/EmbedYoutubeAmp'

const AuthorDetails = loadable(() => import("./authorDetails.amp"));
const WhatNextMobile = loadable(() => import("../components/whatNext/mobileRecommended.amp"));

class Article extends React.Component {

    constructor(props) {
        super(props);
        this.data = props.pageContext.article;
        this.url = `${metadata.url}${props.pageContext.url}`;
        this.canonicalUrl = `${metadata.url}${props.pageContext.canonicalUrl}`;
        this.categories = _orderBy(this.data.category, ['position'], ['asc']);
        this.subCategory = this.categories.find(item => item.treeParent !== null);
        this.longAdCount = 0;

        this.getRecommendedPosts();
    }

    getRecommendedPosts() {
        this.recommendedPosts = [];
        this.popularPosts = [];

        const slugs = [];
        let posts = [];
        const relatedArticlesObject = this.props.pageContext.relatedArticles
        posts = posts
            .concat(relatedArticlesObject.tags10 || [])
            .concat(relatedArticlesObject.tags9 || [])
            .concat(relatedArticlesObject.tags8 || [])
            .concat(relatedArticlesObject.tags7 || [])
            .concat(relatedArticlesObject.tags6 || [])
            .concat(relatedArticlesObject.tags5 || [])
            .concat(relatedArticlesObject.tags4 || [])
            .concat(relatedArticlesObject.tags3 || [])
            .concat(relatedArticlesObject.tags2 || [])
            .concat(relatedArticlesObject.tags1 || [])
            .concat(relatedArticlesObject.sameCategory || [])
            .concat(relatedArticlesObject.random || []);

        const articleCount = 2;

        for (let i = 0; i < posts.length && !isMobile; i++) {
            const post = posts[i];
            if (!slugs.includes(post.slug)) {
                this.popularPosts.push(post);
                slugs.push(post.slug);
            }
            if (this.popularPosts.length === 5) {
                break;
            }
        }

        for (let i = 0; i < posts.length; i++) {
            const post = posts[i];
            if (!slugs.includes(post.slug)) {
                this.recommendedPosts.push(post);
                slugs.push(post.slug);
            }
            if (this.recommendedPosts.length === articleCount) {
                break;
            }
        }
    }

    replace_nth(s, f, r, n) {
        // From the given string s, replace f with r of nth occurrence
        return s.replace(RegExp("^(?:.*?" + f + "){" + n + "}"), x => x.replace(RegExp(f + "$"), r));
    };

    getContent() {
        let content = this.data.content.replace(/(?:\r\n|\r|\n)/g, '');
        content = content.replace(/ aria-level="1"/g, '');
        content = content.replace(/ style="font-weight: 400;"/g, '');
        content = content.replace(/ padding-left:40px/g, '');

        let contentArray = [];
        if (this.props.pageContext.pageNumber) {
            contentArray = this.replace_nth(content, "<h2>", "$pension$<h2>", this.props.pageContext.pageNumber).split("$pension$");
            contentArray = this.replace_nth(contentArray[1], "<h2>", "$pension$<h2>", 2).split("$pension$");
            content = contentArray[0];
        } else {
            contentArray = content.split("<h2>");
            content = contentArray[0];
        }

        return content;
    }

    render() {
        return <Layout>
            <Helmet>
                <meta name="keywords" content={this.data.tags}/>
            </Helmet>
            <Basic seo={this.data.seo}/>
            <Twitter seo={this.data.seo} url={this.url}/>
            <Facebook seo={this.data.seo} url={this.url}/>
            <main className="wrapper">
                <article className="w-full">
                    <CategoryTitleBordered className={"uppercase"}
                                           parentLink={`/${this.subCategory.treeParent.slug}`}
                                           parentTitle={this.subCategory.treeParent.title}
                                           link={`/${this.subCategory.treeParent.slug}/${this.subCategory.slug}`}
                                           title={this.subCategory.title}/>
                    <header className="font-arial">
                        {!this.props.pageContext.pageNumber && this.data.ptNewsVideo && <div className={"news-video mx-2 md:mx-auto"}>
                            <EmbedYoutubeAmp video={this.data.ptNewsVideo}/>
                        </div>}
                        <h1 className="text-2xl lg:text-5xl font-semibold mt-4 mb-0 leading-tight">{this.data.heading}</h1>
                        {!this.props.pageContext.pageNumber && <p className={"font-semibold text-base lg:text-xl font-arial leading-snug mb-0 mt-4"}>{this.data.excerpt}</p>}
                        {!this.props.pageContext.pageNumber && <div className="py-2 lg:py-4 flex items-center">
                            {this.data.author.profilePhoto && <img className="rounded-full mr-2"
                                                                   alt={this.data.author.name} width={80} height={80}
                                                                   src={`${this.data.author.profilePhoto?.url}&w=80&h=80&fit=crop`}/>}
                            <div className="text-black text-opacity-50">
                                <div>
                                    By <a href={`/authors/${this.data.author.slug}`}
                                          className={"text-denim"}>{this.data.author.name}</a>
                                </div>
                                {/*<time>{formatDate(this.data.publishedAt)}</time>*/}
                                <span>&nbsp;·&nbsp;{this.props.pageContext.readingTime} min read</span>
                                {this.data.isSponsored && <span>&nbsp;·&nbsp;sponsored</span>}
                            </div>
                        </div>}
                        <hr className={`border-t-default my-6 mx-10 header-separator`}/>
                    </header>
                    <main>
                        <div id="article-content" dangerouslySetInnerHTML={{__html: this.getContent()}}/>
                        {this.props.pageContext.totalPages && this.props.pageContext.pageNumber < this.props.pageContext.totalPages &&
                        <div>Read more on the next page</div>}
                    </main>
                    <div className="ad-container">
                        <div className="advertisement-text">Advertisement</div>
                        <amp-ad width="336" height="280" type="doubleclick"
                                data-slot="/22218985137/healthtimes/ht_incontent1_mobile" data-multi-size="300x250"
                                data-multi-size-validation="false"></amp-ad>
                    </div>
                    {this.props.pageContext.totalPages && this.props.pageContext.pageNumber < this.props.pageContext.totalPages &&
                    <a className={'w-full inline-block p-2 text-center bg-denim font-bold text-white text-2xl rounded-sm mt-2'}
                       href={`/${this.props.pageContext.url}/${this.props.pageContext.pageNumber + 1}`}>Next ></a>}
                    <div className={"text-center"}>
                        <Share/>
                    </div>
                  <WhatNextMobile recommendedPosts={this.recommendedPosts}/>
                    <AuthorDetails author={this.data.author} deferImage={true}
                                   nameClass={"text-18 mr-2 text-black text-opacity-75"}
                                   bioClass={"text-black text-opacity-75 mt-2 mb-4 leading-relaxed font-light"}/>
                </article>
            </main>
        </Layout>
    }
}

export default Article
