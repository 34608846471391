import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import {URL} from '../../constants/urls'
import {STRINGS} from '../../constants/strings'
import {FooterAMP} from '@cg-squad/ui-components'
import {FOLLOW_US} from '../../constants/followUs'

const FooterAMPWrapper = (props) => {
  const data = useStaticQuery(graphql`
    query FooterCategoryAMPQuery {
      allCategories: allDatoCmsCategory(
        filter: {root: {eq: true}, slug: {ne: "news"}, website: {elemMatch: {name: {eq: "ht"}}}}
        sort: {order: ASC, fields: position}
      ) {
        nodes {
          slug
          title
        }
      }
    }
  `)

  return <FooterAMP categoryPath={props.categoryPath} subCategoryPath={props.subCategoryPath}
                    allCategories={data.allCategories} stringsConst={STRINGS} urlsConst={URL}
                    followUs={FOLLOW_US}/>
}

export default FooterAMPWrapper
